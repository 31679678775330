<template>
  <v-card flat>
    <v-container>
     
        <v-row justify="center">
          <p class="text-h5">{{ $t("Password settings") }}</p>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="4">
            <v-select
              outlined
              dense
              :label="$t('User')"
              v-model="user"
              :items="users"
              item-value="id"
              item-text="name"
            ></v-select>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="4">
            <v-text-field
              outlined
              dense
              v-model="password"
              :label="$t('Password')"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show1 = !show1"
              counter
              :hint="$t('At least 8 characters')"
              :rules="[rules.required, rules.min]"
              :type="show1 ? 'text' : 'password'"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="4" align="right">
            <v-btn
              color="#196619"
              dark
              min-width="150"
              :loading="loading"
              @click="save"
              
              >{{ $t("save") }}</v-btn
            >
          </v-col>
        </v-row>

    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "Password",
  data() {
    return {
      loading: false,
      show1: false,
      users: [],
      user: null,
      password: '',
      rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 8 || 'Min 8 characters',
      },
    };
  },
  methods: {
    async initialize() {
      let res = null;
      try {
        res = await this.$http.get("/user");
        this.users = res.data;
      } catch (e) {
        console.log(e);
        this.$emit("alertMsg", {
          type: "error",
          text: e.message + "\n" + res?.error,
        });
      }
    },
    proceedAction(act) {
      this.action = act;
      this.dialog = true;
    },
    async save() {
      let res = null;
      try {
        this.loading = true;
        res = await this.$http.put(`/user`, {
          id: this.user,
          password: this.password,
        });
        this.$emit("alertMsg", {
          type: "success",
          text: "Data saved OK",
        });
      } catch (e) {
        console.log(e);
        this.$emit("alertMsg", {
          type: "error",
          text: e.message + "\n" + res?.error,
        });
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.initialize();
  },
};
</script>
